// JS Goes here - ES6 supported

import { pageTransition } from "./js/pageTransition"
import { menu } from "./js/menu";
import { followCursor } from "./js/cursor";
import { videoPlayer } from "./js/video";
import { responsiveVideoSourceHandler } from "./js/responsiveVideoSourceHandler";
import Glide from '@glidejs/glide';

pageTransition();
menu();
videoPlayer();
followCursor();
responsiveVideoSourceHandler();

if (document.querySelector('.glide')) {
    const settings = {
        type: 'carousel',
        perView: 1,
        focusAt: 'center',
        autoplay: 4000,
        animationDuration: 600,
        animationTimingFunc: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
        dragThreshold: false,
        peek: 500,
        breakpoints: {
            2400: {
                peek: 400
            },
            2150: {
                peek: 300
            },
            1900: {
                peek: 250
            },
            1440: {
                peek: 200
            },
            1024: {
                peek: 150
            },
            768: {
                peek: 0
            }
        }
    };

    let glide = new Glide('.glide', settings ).mount();

    const sliderToggleAutoplay = (clickable) => {
        document.querySelector(clickable).addEventListener('click', () => {
            if (document.querySelector('#menu').classList.contains('show')) {
                glide.update({ autoplay: false});
            } else {
                glide.update({ autoplay: 4000 });
            }
        });
    };

    let windowWidthOnPageCall = window.innerWidth;
    let timer;

    window.addEventListener('resize', ()=>{
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (window.innerWidth !== windowWidthOnPageCall)
                glide.update();
        }, 50)
    });

    sliderToggleAutoplay('#menu');
    sliderToggleAutoplay('#menu-btn');
}