export function responsiveVideoSourceHandler() {
    let timer;
    let windowWidthOnPageCall = window.innerWidth;
    let videoWrapper = document.querySelector('header.header');

    if (videoWrapper) {
        let video = videoWrapper.querySelector('video');
        let sources = [
            {
                src: 'assets/video/header-video/header_video_sm.mp4',
                size: 'sm'
            },
            {
                src: 'assets/video/header-video/header_video_md.mp4',
                size: 'md'
            },
            {
                src: 'assets/video/header-video/header_video_xl.mp4',
                size: 'xl'
            }
        ];

        let determineSourceViaWindowSize = () => {
            if (window.innerWidth <= 768) {
                video.setAttribute('src', sources[0].src)
            } else if (window.innerWidth <= 1440) {
                video.setAttribute('src', sources[1].src)
            } else {
                video.setAttribute('src', sources[2].src)
            }
        }

        determineSourceViaWindowSize();

        window.addEventListener('resize', ()=>{
            clearTimeout(timer);
            timer = setTimeout(() => {
                if (window.innerWidth !== windowWidthOnPageCall)
                    determineSourceViaWindowSize();
            }, 50)
        });
    }
}
