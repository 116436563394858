const cursor = document.querySelector('#circle');
const followCursorAreas = Array.prototype.slice.call(document.querySelectorAll('.follow-cursor'));
const arrowLeftIcon = '<svg class="arrowLeft-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.57 34.06"><title>left-icon</title><g id="aafb8e8a-6519-4164-a131-889063324a38" data-name="Ebene 2"><g id="f7e23cdc-7069-411c-b3b2-c5a0171c1d68" data-name="Ebene 1"><polygon points="3.47 14.71 24.21 0 26.53 3.26 6.95 17.13 26.57 30.78 24.29 34.06 3.49 19.59 0 17.17 3.47 14.71"/></g></g></svg>';
const arrowRightIcon = '<svg class="arrowRight-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.57 34.06"><title>right-icon</title><g id="bf2f1d46-6ebe-4449-95f2-64f9fdf2aa2c" data-name="Ebene 2"><g id="ffc558d5-6a6b-4106-8719-d43ae6d56f27" data-name="Ebene 1"><polygon points="23.1 14.71 2.36 0 0.04 3.26 19.62 17.13 0 30.78 2.28 34.06 23.08 19.59 26.57 17.17 23.1 14.71"/></g></g></svg>';
const playIcon = '<svg class="play-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 32"><title>play-icon</title><g id="f30ce043-0254-4260-a523-f451f2191870" data-name="Ebene 2"><g id="a67df525-612b-452d-aab6-4131f1bc0d7e" data-name="Ebene 1"><path d="M0,0V32L24,16ZM4,8l13,8L4,24Z"/></g></g></svg>';
const pauseIcon = '<svg class="pause-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24"><title>pause-icon</title><g id="a8056e78-3c6d-4842-a128-f4574754759d" data-name="Ebene 2"><g id="fe760acd-ef55-4137-a734-cf32b1e8e47b" data-name="Ebene 1"><rect width="4" height="24"/><rect x="12" width="4" height="24"/></g></g></svg>';
const closeIcon = '<div class="close-cursor"><span></span><span></span></div>';
const circle = document.querySelector('#circle');

const mousemoveHandler = function (position) {
    let x = position.clientX;
    let y = position.clientY;
    let newposX = x - 24;
    let newposY = y - 24;

    cursor.style.transform = "translate3d(" + newposX + "px, " + newposY + "px, 0px)";
};

const cursorIconIdentifier = (cursorArea) => {
    if (cursorArea.classList.contains('glide-btn-next')) {
        circle.innerHTML = arrowRightIcon;
    } else if (cursorArea.classList.contains('glide-btn-prev')) {
        circle.innerHTML = arrowLeftIcon;
    } else if (cursorArea.classList.contains('video')) {
        circle.innerHTML = playIcon;

        if (cursorArea.classList.contains('playing')) {
            circle.innerHTML = pauseIcon;
        } else if (cursorArea.classList.contains('paused')) {
            circle.innerHTML = playIcon;
        }
    } else if (cursorArea.classList.contains('menu') || (cursorArea.classList.contains('menu-btn') &&  document.querySelector('#menu').classList.contains('show'))) {
        document.querySelector('ul').addEventListener('mouseover', () => {
            circle.classList.add('close-cursor-hidden');
        });

        document.querySelector('ul').addEventListener('mouseout', () => {
            circle.classList.remove('close-cursor-hidden');
        });
        circle.innerHTML = closeIcon;
    } else {
        circle.innerHTML = "";
    }
};

export function followCursor() {
    let menu = document.querySelector('#menu-btn');
    let menuPosition = menu.getBoundingClientRect();
    let menuPosX = menuPosition.left;
    let menuPosY = menuPosition.top;

    cursor.style.transform = "translate3d(" + menuPosX + "px, " + menuPosY + "px, 0px)";
    cursor.classList.add('fixed-position');

    window.addEventListener('resize', ()=> {
        menuPosition = menu.getBoundingClientRect();
        menuPosX = menuPosition.left;
        menuPosY = menuPosition.top;

        cursor.style.transform = "translate3d(" + menuPosX + "px, " + menuPosY + "px, 0px)";
        cursor.classList.add('fixed-position');
    });

    if(window.innerWidth > 768) {
        if (!(window.getComputedStyle(cursor).display === "none")) {
            followCursorAreas.forEach(function(cursorArea) {
                // When Cursor is on an element with the class .follow-cursor > #circle gets its position
                cursorArea.addEventListener('mousemove', ()=> {
                    cursorIconIdentifier(cursorArea);

                    cursorArea.addEventListener('mousemove', (position) => mousemoveHandler(position));
                    cursor.classList.remove('fixed-position');
                });
                cursorArea.addEventListener('click', ()=> {
                    cursorIconIdentifier(cursorArea);
                });
                // When Cursor is on an element with the class .follow-cursor > #circle gets its position
                cursorArea.addEventListener('mouseout', function f() {
                    cursorIconIdentifier(cursorArea);

                    cursorArea.removeEventListener('mousemove', (position) => mousemoveHandler(position));
                    cursor.style.transform = "translate3d(" + menuPosX + "px, " + menuPosY + "px, 0px)";
                    cursor.classList.add('fixed-position');
                });
            });
        }
    }
}