export function pageTransition() {
    const links = Array.prototype.slice.call(document.querySelectorAll('.js-transition-selector'));

    links.forEach(link => {
        let url = link.getAttribute('href');

        link.addEventListener('click', (event)=> {
            event.preventDefault();
            document.documentElement.classList.add('will-animate');
            document.documentElement.classList.add('page-transition-state');

            setTimeout( ()=> {
                window.location.href = url;
            }, 1000 );
        });
    });

    window.addEventListener('load', () => { // fires when *EVERYTHING* is loaded
            document.documentElement.classList.remove('will-animate');
        setTimeout( ()=> {
            document.documentElement.classList.remove('page-transition-state');
        }, 500 );
    });
}