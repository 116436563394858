
export class VideoPlayer {
    constructor(wrapper) {
        this.videoWrapper = wrapper;
        this.fullWidthVideo = this.videoWrapper.classList.contains('full-width-video');
        this.videoContainer = this.videoWrapper.querySelector('.video-container');
        this.videoPosterContainer = this.videoWrapper.querySelector('.video-poster-container');
        this.video = this.videoContainer.querySelector('video');
        this.poster = this.videoContainer.querySelector('img');
        this.videoInfo = this.videoContainer.querySelector('.video-credits');
        this.infoIcon = wrapper.querySelector('.video-info-icon');
        this.infoWrapper = wrapper.querySelector('.video-info-wrapper');
        this.closeIcon = wrapper.querySelector('.video-close-icon');
        this.progressBarWrapper = this.videoContainer.querySelector('.timebar-wrapper');
        this.progressBarCurrentProgress = this.progressBarWrapper.querySelector('.timebar-progress');
        this.progressBarTimestamp = this.progressBarCurrentProgress.querySelector('.timebar-timestamp');
        this.noPreview = this.videoContainer.querySelector('.no-preview');
        this.infoIsVisible = false;
        this.videoPaused = true;
        this.inFocus = false;
        this.videoPreviewRunning = false;
        this.mobileView = window.innerWidth <= 768;
        this.video.ended;
    }

    init() {
        this.initEventListeners();
    }

    // scroll until wrapper.getBoundingClientRect().top = (window.innerHeight - wrapper.getBoundingClientRect().height) / 2
    initEventListeners() {
        // Play or pause video respecting screen width
        this.video.addEventListener('click', () => {
            if (this.mobileView) { // Just play the video and blur the background on mobile devices
                if (this.videoPaused) {
                    this.playVideo();
                    if (!this.fullWidthVideo)
                        this.videoFocusIn();
                }
                else
                    this.pauseVideo();
            } else if (!this.mobileView) {
                // Play the video, put it in focus and blur the background on larger devices
                if (this.videoPaused && !this.inFocus) {
                    this.playVideo();
                    if (!this.fullWidthVideo)
                        this.videoFocusIn(); // Puts the video in focus. Can be closed by (x) icon -> this.videoFocusOut();
                } else if(this.videoPaused && this.inFocus) {
                    this.playVideo();
                } else {
                    this.pauseVideo();
                }
            }
        });

        if (this.infoIcon) {
            // Toggle video infos
            this.infoIcon.addEventListener('click', () => {
                if (this.infoIsVisible)
                    this.videoInfoClose();
                else
                    this.videoInfoOpen();
            });

            // Close video focus
            this.closeIcon.addEventListener('click', () => {
                if (this.inFocus) {
                    this.videoFocusOut();
                    this.stopVideo();
                }
            });
        }

        window.addEventListener('load', ()=> {
            this.sourceChangeHandler();
        });

        // Run video preview
        this.video.addEventListener('mouseenter', () => {
            if (!this.is_touch_device() && !this.inFocus && this.videoPaused && !this.videoPreviewRunning && !this.noPreview)
                this.videoPreviewStart();
        });

        // End video preview
        this.video.addEventListener('mouseout', () => {
            if (!this.is_touch_device() && !this.inFocus && this.videoPreviewRunning && this.videoPaused && !this.noPreview)
                this.videoPreviewStop();
        });

        this.progressBarWrapper.addEventListener('mousedown', (cursorPositionEvent) => {
            this.progressBarDimensions = this.progressBarWrapper.getBoundingClientRect();
            this.cursorPositionX = cursorPositionEvent.clientX; // cursor position on the x-axis of the page
            this.cursorPositionOnTimebar = this.cursorPositionX - this.progressBarDimensions.x;
            this.percentage = Math.round((this.cursorPositionOnTimebar / this.progressBarDimensions.width) * 100);

            this.progressBarCurrentProgress.style.width = this.percentage + "%";
            this.video.currentTime = Math.floor(this.percentage * this.video.duration) / 100;

        });

        this.video.addEventListener('timeupdate', () => {
            this.videoProgressPercentage = this.video.currentTime / this.video.duration * 100;
            this.minutes = Math.floor(this.video.currentTime / 60);
            this.seconds = Math.floor(this.video.currentTime - this.minutes * 60);

            if (this.seconds < 10) {
                this.seconds = '0' + this.seconds;
            }

            if (this.video.currentTime === this.video.duration) {
                setTimeout( () => {
                    if (!this.fullWidthVideo)
                        this.videoFocusOut();
                    this.stopVideo();
                }, 700)
            }

            this.progressBarCurrentProgress.style.width = this.videoProgressPercentage + "%";
            this.progressBarTimestamp.textContent = this.minutes + ":" + this.seconds;
        });

        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                if (!this.fullWidthVideo)
                    this.videoFocusOut();
                this.stopVideo();
            }
        })
    }

    pauseVideo() {
        this.video.pause();
        this.videoContainer.classList.add('paused');
        this.video.classList.add('paused');
        this.videoPosterContainer.classList.remove('playing')
        this.videoContainer.classList.remove('playing');
        this.video.classList.remove('playing');
        this.videoPosterContainer.classList.add('paused')
        this.videoContainer.classList.add('paused');
        this.video.classList.add('paused');
        this.videoPaused = true;
    }

    playVideo() {
        this.video.play().then(function() {
        }).catch(function(error) {
            console.log(error)
        });
        this.poster.classList.add('hidden-behind');
        this.videoPosterContainer.classList.add('playing')
        this.videoContainer.classList.add('playing');
        this.video.classList.add('playing');
        this.videoPosterContainer.classList.remove('paused')
        this.videoContainer.classList.remove('paused');
        this.video.classList.remove('paused');
        this.video.muted = false;
        this.videoPaused = false;
        this.videoPreviewRunning = false;
    }

    stopVideo() {
        this.video.load();
        this.poster.classList.remove('hidden-behind');
        this.videoPosterContainer.classList.remove('playing')
        this.videoContainer.classList.remove('playing');
        this.video.classList.remove('playing');
        this.videoPosterContainer.classList.remove('paused')
        this.videoContainer.classList.remove('paused');
        this.video.classList.remove('paused');
        this.progressBarCurrentProgress.style.width = "0";
        this.progressBarTimestamp.textContent = "";
        this.video.muted = true;
        this.videoPaused = true;
        this.videoPreviewRunning = false;
    }

    videoPreviewStart() {
        this.video.muted = true;
        this.poster.classList.add('hidden-behind');
        this.video.play();
        this.videoPreviewRunning = true;
    }

    videoPreviewStop() {
        this.video.muted = false;
        this.poster.classList.remove('hidden-behind');
        this.videoPreviewRunning = false;
        this.videoPaused = true;
        this.video.pause();
        this.video.currentTime = 0;
        this.video.load();
    }

    videoInfoOpen() {
        this.videoInfo.classList.remove('hidden');
        this.infoIsVisible = true;
    }

    videoInfoClose() {
        this.videoInfo.classList.add('hidden');
        this.infoIsVisible = false;
    }

    videoFocusIn() {
        setTimeout(() =>{
            this.infoWrapper.querySelector('.video-info-title').style.display = 'none';
            if (window.innerWidth >= 1024)
                this.infoWrapper.style.position = 'absolute';
        }, 300);
        setTimeout(() =>{
            this.infoWrapper.classList.add('video-controls');
            if (this.closeIcon)
                this.closeIcon.style.display = 'block';
            this.infoWrapper.classList.remove('animating');
        }, 1300);

        this.videoWrapper.classList.add('video-focus');
        this.videoContainer.classList.add('animating');
        this.infoWrapper.classList.add('animating');

        this.videoContainer.classList.remove('animating-backwards');
        this.infoWrapper.classList.remove('animating-backwards');
        setTimeout(()=> {
            this.videoWrapper.scrollIntoView({block: "center", behavior: "smooth"});
        }, 1000);
        document.documentElement.classList.add('got-video-focus');
        this.inFocus = true;
    }

    videoFocusOut() {
        this.videoContainer.classList.add('animating-backwards');
        this.infoWrapper.classList.add('animating-backwards');
        this.closeIcon.style.display = 'none';
        this.infoWrapper.querySelector('.video-info-title').style.display = 'block';
        this.infoWrapper.style.position = 'static';
        setTimeout(()=>{
            this.videoWrapper.classList.remove('video-focus');
            this.videoContainer.classList.remove('animating-backwards');
            this.infoWrapper.classList.remove('animating-backwards');
            this.infoWrapper.classList.remove('video-controls');
        }, 300);
        document.documentElement.classList.remove('got-video-focus');
        this.inFocus = false;
    }

    is_touch_device() {
        let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

        let mq = function (query) {
            return window.matchMedia(query).matches;
        };

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        let query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }

    sourceChangeHandler() {
        let desktopVideoSource = `assets/video/projects/video/desktop/`;
        let mobileVideoSource = `assets/video/projects/video/mobile/`;
        let video = this.video;
        let videoSrc = video.getAttribute('src');
        let newVideoSrc;


        if (!this.is_touch_device()) { // Checks if the device the website got called on is not a touch device.
            newVideoSrc = videoSrc.replace(mobileVideoSource, desktopVideoSource);
            video.setAttribute('src', newVideoSrc);
            this.video.load();
        }
    }
}

export function videoPlayer() {
    const videoWrappers = Array.prototype.slice.call(document.querySelectorAll('.video-wrapper'));

    videoWrappers.forEach(wrapper => {
        let singleVideoPlayer = new VideoPlayer(wrapper);

        singleVideoPlayer.init();
    });
}