export function menu() {
    const menuBtn = document.querySelector('#menu-btn');
    const menu = document.querySelector('#menu');
    const header = document.querySelector('header');
    const videos = Array.prototype.slice.call(document.querySelectorAll('video'));

    const closeMenu = () => {
        if (videos && header) {
            videos.forEach(video => {
                video.play();
            });
        }
        menuBtn.classList.remove('hidden', 'active');
        menu.classList.remove('show');
        document.body.classList.remove('is-child-blurred');
        document.documentElement.classList.remove('no-scroll');
    };

    const openMenu = () => {
        if (videos && header) {
            videos.forEach(video => {
                video.pause();
            });
        }
        menuBtn.classList.add('hidden', 'active');
        menu.classList.add('show');
        document.body.classList.add('is-child-blurred');
        document.documentElement.classList.add('no-scroll');
    };

    const toggleMenu = () => {
        if (!menu.classList.contains('show')) {
            openMenu();
        } else {
            closeMenu();
        }
    };

    menuBtn.addEventListener('click',()=> {
        toggleMenu();
    });

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            closeMenu();
        }
    });

    menu.addEventListener('click', (event) => {
        if (!event.target !== document.querySelector('ul')) {
            closeMenu();
        }
    });
}
